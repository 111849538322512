<template>
  <div class="checkbox" :class="{ checked: isChecked }" @click="handleCheckboxChange">
    <input
      type="checkbox"
      :checked="isChecked"
      @change="handleCheckboxChange"
    >
    <label class="checkbox-label">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    selectedValues: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isChecked () {
      return this.selectedValues.some((value) => {
        if (this.value.id) {
          return value.key === this.value.id
        }

        return value.key === this.value.key
      })
    }
  },
  methods: {
    handleCheckboxChange () {
      this.$emit('checked', this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background: #F6ECF7;

    & .checkbox-label {
      color: #730D83;
    }
  }

  input[type="checkbox"] {
    accent-color: #730D83;
    width: 16px;
    height: 16px;
    pointer-events: none;
  }

  &.checked {
    transition: all 0.3s ease;
    background: #F6ECF7;

    & .checkbox-label {
      color: #730D83;
    }
  }

  &-label {
    font-size: var(--size-button);
    font-weight: var(--weight-light);
    color: var(--grey-dark-custom);
    cursor: pointer;
  }
}
</style>
