<template>
  <div class="properties-list">
    <section class="properties-header">
      <div class="container">
        <ul>
          <li>{{ stateText.attributes?.name || 'Todos' }}</li>
          <li><span>•</span>{{ cityText.attributes?.name || 'Todos' }}</li>
        </ul>
        <label v-if="resultText.isVisible">{{ resultText.summary }}</label>
      </div>
    </section>
    <section class="properties-list--filter-container">
      <section class="properties-filter">
      <div class="container">
        <div class="container--cell">
          <DropdownSelect
            :selectedValue="selectedSaleType"
            :items="saleTypes"
            style="max-width: 170px;"
            unremovable
          />
          <DropdownSelect
            @selected="handleSelectPropertyType"
            :selectedValue="typeSelected"
            :items="propertyTypes"
            label="Tipo de imóvel"
            style="max-width: 200px;"
          />
          <DropdownSelect
            @selected="handleSelectMaxBedrooms"
            :selectedValue="bedrooms.max"
            :items="defaultMinMaxOptions.max.filter((item) => item !== 'Todos')"
            label="Quartos"
            style="max-width: 130px;"
          />
          <Money v-model="maximumValue" v-bind="money" placeholder="Valor médio (R$)" style="max-width: 250px;" />
          <input v-model="searchText" class="search-input" placeholder="Buscar Endereço/Empreendimento" type="text" />
        </div>
        <div class="container--cell">
          <DropdownSelect
            @selected="handleSelectState"
            :selectedValue="stateSelected"
            :items="states.map((state) => { return { key: state.id, name: state.attributes.name } }).filter((state) => (state.name !== 'Todos'))"
            label="Estado"
            style="max-width: 420px;"
          />
          <DropdownSelect
            @selected="handleSelectCity"
            :selectedValue="citySelected?.id"
            :items="cities.map((city) => { return { key: city.id, name: city.attributes.name } }).filter((city) => (city.name !== 'Todos'))"
            :disabled="loader"
            :isLoading="loader"
            label="Cidades"
            style="max-width: 420px;"
          />
          <button class="button-secondary" :class="{ 'active-filters': advancedFiltersCounter > 0 }" @click="openModal" :disabled="loader" style="min-width: 152px;">
            Mais filtros

            <span v-if="advancedFiltersCounter > 0">{{ advancedFiltersCounter }}</span>
          </button>
          <button class="button-primary" @click="handleFilter" style="min-width: 152px;">
            Pesquisar
          </button>
        </div>
      </div>
    </section>
    </section>
    <div v-if="isLoading" class="loader">
      <img src="~@/assets/loader.gif" alt="">
      <p>Carregando imóveis...</p>
    </div>
    <section v-else-if="properties.length || properties.length > 0" class="properties-section">
      <div class="container container-properties">
        <div
          class="box-properties"
          v-for="item in properties"
          :value="item.id"
          :key="item.id"
        >
          <router-link :to="{ name: 'Properties', params: { slug: item.attributes.slug, id: item.id } }" target="_blank" exact>
            <img v-if="getPhoto(item) != null" class="properties-image" alt="" :src="getPhoto(item)">
            <img v-else class="properties-image" src="~@/assets/placeholder-properties.png" alt="">

            <div class="properties-content">
              <p class="tag"></p>
              <div>
                <router-link :to="{ name: 'Properties', params: { slug: item.attributes.slug, id: item.id } }" exact>
                  <h3>{{ item.attributes.name }}</h3>
                </router-link>

                <router-link :to="{ name: 'Properties', params: { slug: item.attributes.slug, id: item.id } }" exact>
                  <p class="locale">{{ item.attributes.address }}</p>
                </router-link>

                <p
                  class="infos"
                  v-for="features in item.attributes.features.slice(0,2)"
                  :value="features.id"
                  :key="features.id"
                >
                  {{ features.name }}
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="view-more" v-if="toggleButtonMore">
        <a @click="viewMore">Carregar mais</a>
      </div>
    </section>
    <div v-else class="empty">
      <img src="~@/assets/image-empty.svg" alt="">
      <h3>Oops!</h3>
      <p>Não foi possivel encontrar o que está procurando, filtre novamente e encontre o imóvel ideal.</p>
    </div>

    <PropertiesModal
      v-if="showModal"
      @close="closeModal"
      @filter="handleAdvancedFilter"
      :propertyTypes="propertyTypes"
      :states="states"
      :features="benefits"
      :cities="cities"
      :saleTypes="saleTypes"
      :preSetValues="{
        selectedSaleType: selectedSaleType,
        selectedPropertyType: typeSelected,
        selectedState: stateSelected,
        selectedCity: citySelected,
        selectedMinimumValue: minimumValue,
        selectedMaximumValue: maximumValue,
        features: features,
        payments: payments,
        statuses: statuses,
        bedrooms: bedrooms,
        garage: garage,
        suites: suites,
        footage: footage,
        search: searchText
      }"
    />
  </div>
</template>

<script>
import { propertiesService } from '@/services/properties.service'
import { regionsService } from '@/services/regions.service'
import { Money } from 'v-money'
import { pushQueryParams, replaceQueryParams } from '@/utils/historyState'
import { propertyTypes, defaultMinMaxOptions, saleTypes } from '@/components/properties/properties-options'
import PropertiesModal from '@/components/properties/PropertiesModal.vue'
import DropdownSelect from '@/components/select/DropdownSelect.vue'
const slugify = require('slugify')

export default {
  name: 'PropertiesList',
  components: { Money, PropertiesModal, DropdownSelect },
  data () {
    return {
      isLoading: false,
      isFiltered: false,
      loader: false,
      empty: false,
      firstValue: { id: '', attributes: { name: 'Todos' } },
      properties: [],
      states: [],
      cities: [],
      benefits: [],
      propertyTypes,
      minimumValue: '',
      maximumValue: '',
      stateSelected: '',
      citySelected: '',
      benefitsSelected: '',
      features: [],
      payments: [],
      statuses: [],
      typeSelected: '',
      selectedSaleType: '',
      propertyType: '',
      bedroomSelected: '',
      bedrooms: {
        min: '',
        max: ''
      },
      suites: {
        min: '',
        max: ''
      },
      garage: {
        min: '',
        max: ''
      },
      searchText: '',
      currentPage: 1,
      perPage: 12,
      totalItems: 0,
      totalPages: 0,
      toggleButtonMore: true,
      activeSearchBar: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: ' ',
        precision: 2,
        masked: false
      },
      showModal: false,
      footage: {
        min: '',
        max: ''
      },
      requestSent: false,
      defaultMinMaxOptions,
      saleTypes
    }
  },

  computed: {
    stateText () {
      return this.findItem(this.stateSelected, this.states)
    },

    cityText () {
      return this.findItem(this.citySelected?.id, this.cities)
    },

    benefitsText () {
      return this.findItem(this.benefitsSelected, this.benefits)
    },

    typeText () {
      return this.findItem(this.typeSelected, this.propertyTypes, 'key')
    },

    bedroomText () {
      return this.findItem(this.bedroomSelected, this.defaultMinMaxOptions.max, 'key')
    },

    resultText () {
      const numberOfProperties = this.totalItems || 0
      const texts = {
        bedroom: this.bedroomSelected > 1 ? 'quartos' : 'quarto',
        properties: numberOfProperties > 1 ? 'imóveis' : 'imóvel',
        suites: this.suites.max > 1 ? 'suites' : 'suite',
        garage: this.garage.max > 1 ? 'garagens' : 'garagem',
        found: numberOfProperties > 1 ? 'encontrados' : 'encontrado'
      }
      const state = (this.stateText.attributes?.name !== undefined && this.stateText.attributes?.name !== '' && this.stateText.attributes?.name !== 'Todos') ? this.stateText.attributes?.name : 'todos os estados'
      const city = (this.cityText.attributes?.name !== undefined && this.cityText.attributes?.name !== 'Todos') ? this.cityText.attributes?.name : ''
      const benefits = (this.benefitsText.attributes?.name !== undefined && this.benefitsText.attributes?.name !== 'Todos') ? `com ${this.benefitsText.attributes?.name}` : ''
      const bedrooms = (this.bedroomText?.value !== undefined && this.bedroomText?.value !== 'Todos') ? `até ${this.bedroomText?.value} ${texts.bedroom}` : ''
      const suites = (this.suites.max !== undefined && this.suites.max !== '') ? `com ${this.suites.max === 999 ? '5 ou mais' : this.suites.max} ${texts.suites}` : ''
      const garage = (this.garage.max !== undefined && this.garage.max !== '') ? `até ${this.garage.max === 999 ? '5 ou mais' : this.garage.max} ${texts.garage}` : ''
      const footage = (this.footage.min !== '' && this.footage.max !== '') ? `entre ${this.footage.min} a ${this.footage.max} m²` : ''
      const price = ((this.minimumValue !== '' && this.minimumValue !== 0) && (this.maximumValue !== '' && this.maximumValue !== 0)) ? `com preços entre R$ ${this.getPrice(this.minimumValue)} à R$ ${this.getPrice(this.maximumValue)}` : ''
      const payment = (this.payments.length > 0) ? `e que ${this.payments.flatMap(item => item.name).join(' e ')}` : ''
      const type = (this.typeText.name !== undefined && this.typeText.name !== '') ? `${this.typeText.name?.toLowerCase()}s` : `${texts.properties}`

      if (state !== '' && city !== '') {
        const value = `${numberOfProperties} ${type} ${footage} ${texts.found} ${suites} ${garage} ${bedrooms} ${benefits} ${price} em ${city}/${state} ${payment}`
        document.title = `imoGo - ${value}`
        return { isVisible: this.isFiltered, summary: value }
      }

      const value = `${numberOfProperties} ${type} ${footage} ${texts.found} ${suites} ${garage} ${bedrooms} ${benefits} ${price} em ${city} ${state} ${payment}`
      document.title = `imoGo - ${value}`
      return { isVisible: this.isFiltered, summary: value }
    },

    advancedFiltersCounter () {
      const { features, suites, garage, payments, statuses, maximumValue, minimumValue, footage } = this.getFilters()

      return [
        features,
        minimumValue,
        maximumValue,
        suites.max,
        garage.max,
        footage.min,
        footage.max,
        statuses,
        payments
      ].filter(value => {
        if (Array.isArray(value)) return value.length > 0
        if (typeof value === 'number') return value >= 1
        if (typeof value === 'string') return value !== ''
        if (typeof value === 'object') return Object.values(value).some(v => v !== '')
      }).length
    }
  },

  created () {
    this.loader = true
    const { state, city } = this.$route.query

    pushQueryParams(
      {
        state: state || '',
        city: city || ''
      }
    )
  },

  async mounted () {
    await this.initialize()
    this.getProperties(true)
    this.getBenefits()
  },

  methods: {
    async initialize () {
      const { estado, cidade, vantagens, tipo, busca, valorMinimo, valorMaximo, qtdQuartos, qtdSuites, qtdGaragens, areaInicial, areaFinal } = this.$route.query

      await this.getStates()

      if (estado && cidade) {
        this.stateSelected = estado
        this.updateQueryParams('', estado, cidade)
      } else {
        this.stateSelected = this.states.at(1)?.id || ''
      }

      await this.getCities(this.stateSelected || '')

      if (cidade) {
        this.citySelected = this.cities.find((c) => c?.id === cidade)
      } else {
        this.citySelected = ''
      }

      if (vantagens) {
        this.benefitsSelected = vantagens
      }

      if (tipo) {
        this.typeSelected = tipo
      }

      if (valorMinimo != null && valorMinimo !== '0') {
        const value = valorMinimo || 0
        this.minimumValue = parseInt(value)
      }

      if (valorMaximo != null && valorMaximo !== '0') {
        const value = valorMaximo
        this.maximumValue = parseInt(value)
      }

      if (qtdQuartos != null && qtdQuartos !== '0') {
        const value = qtdQuartos
        this.bedroomSelected = parseInt(value)
        this.bedrooms.max = parseInt(value)
      }

      if (qtdSuites != null && qtdSuites !== '0') {
        const value = qtdSuites
        this.suites.max = parseInt(value)
      }

      if (qtdGaragens != null && qtdGaragens !== '0') {
        const value = qtdGaragens
        this.garage.max = parseInt(value)
      }

      if (areaInicial != null && areaInicial !== '0') {
        const value = areaInicial || 0
        this.footage.min = parseInt(value)
      }

      if (areaFinal != null && areaFinal !== '0') {
        const value = areaFinal
        this.footage.max = parseInt(value)
      }

      if (busca != null && busca !== ' ') {
        this.searchText = busca
      }

      this.updateParams()

      this.propertyType = ''
      this.selectedSaleType = this.saleTypes[0].key
    },

    async handleFilter () {
      await this.getProperties()
    },

    async handleRemoveFilter (key) {
      this.getProperties(true, undefined, key)
    },

    async handleAdvancedFilter (payload) {
      if (payload) {
        this.isFiltered = true
        await this.getProperties(false, payload)
      }
    },

    findItem (value, list, byKey = 'id') {
      const response = list.filter(item => {
        return item[byKey] === value
      }) || null

      if (response.length > 0) {
        return response[0]
      }
      return response
    },

    getPrice (value) {
      if (value) {
        value = (value / 1).toFixed(2).replace('.', ',')
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      return ''
    },

    viewMore () {
      if (this.currentPage < this.totalPages) {
        this.perPage = this.perPage + 12
        this.getProperties()
      }
      if (this.perPage >= this.totalItems) {
        this.toggleButtonMore = false
      }
    },

    updateQueryParams (slug, state, city, benefits, type, search, minValue, maxValue, numberOfBedroom, numberOfSuites, numberOfGarage, minFootage, maxFootage) {
      const queryParams = {
        slug: slug,
        estado: state,
        cidade: city,
        vantagens: benefits,
        tipo: type,
        busca: search,
        valorMinimo: minValue,
        valorMaximo: maxValue,
        qtdQuartos: numberOfBedroom,
        qtdSuites: numberOfSuites,
        qtdGaragens: numberOfGarage,
        areaInicial: minFootage,
        areaFinal: maxFootage
      }

      const result = Object.keys(queryParams).reduce((acc, key) => {
        if (queryParams[key] !== null && queryParams[key] !== undefined && queryParams[key] !== '') {
          acc[key] = queryParams[key]
        }
        return acc
      }, {})

      replaceQueryParams(result)
    },

    getPhoto (property) {
      if (property?.attributes?.building_images.length > 0) {
        const index = property?.attributes?.building_images.length - 1
        return property?.attributes?.building_images[index]
      }

      if (property?.attributes?.images != null) {
        return property?.attributes?.images[0]
      }

      return null
    },

    async getProperties (initialize = false, payload, keyToRemove) {
      this.isLoading = true
      this.loader = true
      try {
        this.requestSent = true
        let minValue = 0
        let maxValue = ''

        if (this.minimumValue || this.maximumValue) {
          this.minimumValue = (this.minimumValue === '') ? 0.0 : this.minimumValue
          this.maximumValue = (this.maximumValue === '') ? 0.0 : this.maximumValue

          minValue = parseInt(this.minimumValue) * 100
          maxValue = parseInt(this.maximumValue) * 100
        }

        if (keyToRemove) {
          this.removeFilter(keyToRemove)
        }

        let params = {
          search: this.searchText,
          state_id: this.stateSelected,
          region_id: this.citySelected?.id,
          price_min: minValue,
          price_max: maxValue,
          property_type: this.typeSelected,
          bedrooms_max: this.bedrooms.max,
          feature_id: this.benefitsSelected,
          currentPage: this.currentPage,
          perPage: this.perPage,
          bedrooms_min: this.bedrooms.max,
          footage_max: this.footage.max,
          footage_min: this.footage.min,
          suites_min: this.suites.max,
          suites_max: this.suites.max,
          garage_min: this.garage.max,
          garage_max: this.garage.max,
          status: this.statuses?.map((status) => status.key),
          payments: this.payments?.map((payment) => payment.key),
          features: this.features?.map((feature) => feature.id),
          is_visible: true
        }

        if (payload) {
          const {
            saleType,
            propertyType,
            state,
            city,
            features,
            bedrooms,
            garage,
            minimumValue,
            maximumValue,
            payments,
            footage,
            suites,
            statuses,
            search
          } = payload
          this.getCities(state)

          this.selectedSaleType = saleType
          this.stateSelected = state
          this.citySelected = city
          this.typeSelected = propertyType
          this.minimumValue = minimumValue
          this.maximumValue = maximumValue
          this.footage = {
            min: footage.min,
            max: footage.max
          }
          this.bedrooms = {
            min: bedrooms.min,
            max: bedrooms.max
          }
          this.garage = {
            min: garage.min,
            max: garage.max
          }
          this.suites = {
            min: suites.min,
            max: suites.max
          }
          this.features = features
          this.payments = payments
          this.statuses = statuses
          this.bedroomSelected = bedrooms.max
          this.benefitsSelected = features.at(0)?.id || ''
          this.searchText = search

          params = {
            ...params,
            state_id: state,
            region_id: city.id,
            property_type: propertyType,
            price_min: this.formatPriceValues(minimumValue),
            price_max: this.formatPriceValues(maximumValue) === 0 ? '' : this.formatPriceValues(maximumValue),
            bedrooms_max: bedrooms.max,
            bedrooms_min: bedrooms.max || 0,
            footage_max: footage.max,
            footage_min: footage.min || 0,
            suites_min: suites.max || 0,
            suites_max: suites.max,
            garage_min: garage.max || 0,
            garage_max: garage.max,
            status: statuses?.map((status) => status.key),
            payments: payments?.map((payment) => payment.key),
            search
          }
        }

        if (this.features?.length > 0) {
          delete params.feature_id
          params.features = this?.features.map((feature) => feature.id)
        }

        const pagination = {
          perPage: this.perPage,
          page: this.currentPage
        }

        const { data: responseData } = await propertiesService.listProperties(params, pagination)

        this.totalItems = responseData.meta.count
        this.totalPages = responseData.meta.pages
        this.currentPage = responseData.meta.page
        this.properties = responseData.data

        if (responseData.data.length < 1) {
          this.empty = true
        } else {
          this.empty = false
        }
      } catch (error) {
        console.log(`Ocorreu um erro inesperado. ${error}`)
      } finally {
        this.resultText.isVisible = true
        this.isLoading = false
        this.loader = false
        this.requestSent = false
        this.updateParams()
      }
    },

    async getStates () {
      this.loader = true
      try {
        const { data: responseData } = await regionsService.retrieveStates()
        this.states = [this.firstValue, ...responseData.data]

        if (responseData.data.length < 1) {
          this.empty = true
        } else {
          this.empty = false
        }
      } catch (error) {
        console.log('Ocorreu um erro inesperado.')
      } finally {
        this.loader = false
      }
    },

    async getCities (stateId) {
      this.loader = true
      try {
        const params = { state_id: stateId }
        const { data: responseData } = await regionsService.retrieveRegions(params)
        this.cities = [this.firstValue, ...responseData.data]
        if (responseData.data.length < 1) {
          this.empty = true
        } else {
          this.empty = false
        }
      } catch {
        console.log('Ocorreu um erro inesperado.')
      } finally {
        this.loader = false
      }
    },

    async getBenefits () {
      this.loader = true
      try {
        const { data: responseData } = await propertiesService.getBenefits()
        this.benefits = [this.firstValue, ...responseData.data]
      } catch {
        console.log('Ocorreu um erro inesperado.')
      } finally {
        this.loader = false
      }
    },

    formatPriceValues (value) {
      const newValue = (value === '') ? 0.0 : value
      return parseInt(newValue) * 100
    },

    removeFilter (key) {
      switch (key) {
        case 'suites':
          this.suites = {
            min: '',
            max: ''
          }
          break
        case 'bedrooms':
          this.bedrooms = {
            min: '',
            max: ''
          }
          break
        case 'garage':
          this.garage = {
            min: '',
            max: ''
          }
          break
        case 'footage':
          this.footage = {
            min: '',
            max: ''
          }
          break
        case 'statuses':
          this.statuses = []
          break
        case 'payments':
          this.payments = []
          break
        case 'features':
          this.features = []
          break
        default:
          break
      }
    },

    resetAdvancedFilterState () {
      this.footage = {
        min: '',
        max: ''
      }
      this.bedrooms = {
        min: '',
        max: ''
      }
      this.garage = {
        min: '',
        max: ''
      }
      this.suites = {
        min: '',
        max: ''
      }
      this.features = []
      this.payments = []
      this.statuses = []
      this.minimumValue = ''
      this.maximumValue = ''
      this.benefitsSelected = ''
      this.typeSelected = ''
      this.bedroomSelected = ''
      this.searchText = ''
      this.citySelected = ''
    },

    getFilters () {
      return {
        minimumValue: this.minimumValue,
        maximumValue: this.maximumValue,
        stateSelected: this.stateSelected,
        citySelected: this.citySelected,
        benefitsSelected: this.benefitsSelected,
        propertyType: this.typeSelected,
        bedroomSelected: this.bedroomSelected,
        bedrooms: this.bedrooms,
        garage: this.garage,
        suites: this.suites,
        features: this.features,
        payments: this.payments,
        statuses: this.statuses,
        searchText: this.searchText,
        footage: this.footage
      }
    },

    openModal () {
      this.showModal = true
    },

    closeModal (reset = false) {
      if (reset) {
        this.resetAdvancedFilterState()
        this.getProperties()
      }

      this.showModal = false
    },

    updateParams () {
      const state = this.stateText.attributes?.name || ''
      const city = this.cityText.attributes?.name || ''
      const maxBedroom = this.bedrooms?.max || ''
      const maxSuites = this.suites?.max || ''
      const maxGarage = this.garage?.max || ''
      const minFootage = this.footage?.min || ''
      const maxFootage = this.footage?.max || ''
      const search = this.searchText || ''

      var minValue = 0
      var maxValue = ''

      if (this.minimumValue || this.maximumValue) {
        minValue = (this.minimumValue === '') ? 0.0 : parseInt(this.minimumValue)
        maxValue = (this.maximumValue === '') ? 0.0 : parseInt(this.maximumValue)
      }

      const items = [state, city]
      var result = items.filter(str => (str.trim() !== '' && !str.includes('Todos'))).join('_')

      const customSlug = slugify(result, { lower: true, remove: undefined })

      this.updateQueryParams(
        customSlug,
        this.stateSelected,
        this.citySelected?.id,
        this.benefitsSelected,
        this.typeSelected,
        search,
        minValue,
        maxValue,
        maxBedroom,
        maxSuites,
        maxGarage,
        minFootage,
        maxFootage
      )
    },

    stateChange () {
      const stateId = this.stateSelected
      this.citySelected = this.cities[0]
      this.getCities(stateId)
    },

    handleSelectState (value) {
      this.stateSelected = value?.key || ''
      this.stateChange()
    },

    handleSelectCity (value) {
      this.citySelected = this.cities.find((city) => city.id === value?.key) || ''
    },

    handleSelectPropertyType (value) {
      this.typeSelected = value?.key || ''
    },

    handleSelectMaxBedrooms (value) {
      this.bedrooms.max = value.key || ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/select";

.properties-list {
  padding: 0 0 60px;

  .loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    margin: 100px 0;
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    margin: 100px 0;
    text-align: center;

    p {
      max-width: 310px;
      margin: 0 auto;
    }

    img {
      margin-bottom: 29px;
    }
  }

  .properties-header {
    background: var(--grey-custom);
    padding: 32px 0;

    .container {
      max-width: 1200px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        font-size: var(--size-large);
        line-height: 36px;
        color: var(--secundary-black);
        font-weight: var(--weight-light);
        display: inline-block;
        vertical-align: middle;

        span {
          margin: 0 6px;
        }

        &:first-child {
          font-weight: var(--weight-bold);
        }
      }
    }

    label {
      font-size: 12px;
      font-weight: 700;
      color: var(--green-custom);
    }
  }

  &--filter-container {
    padding: 24px;
    display: flex;
    margin-top: 16px;
  }

  .properties-filter {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    .container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 12px;
      align-items: center;
      padding: 16px;
      box-shadow: 0px 2px 12px 0px rgba(121, 109, 127, 0.15);
      border-radius: 16px;
      border: 1px solid #EDEDEE;
      background-color: #FFFFFF;

      &--cell {
        display: flex;
        gap: 20px;
        width: 100%;

        &.buttons {
          justify-content: center;
        }
      }
    }

    .shadow {
      flex: 1;
    }

    .search {
      margin-right: 12px;
      background: var(--grey-custom);
      max-width: 324px;
      width: 100%;
      border-radius: 6px 32px 32px 6px;
      display: flex;
      align-items: center;
      padding: 0 23px;
      height: 48px;
      position: relative;

      img {
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }

      .button-primary {
        width: 48px;
        position: absolute;
        right: 0;
        min-width: inherit;

        img {
          margin: 0 0 0 0px;
        }
      }
    }

    .select, input, .search-input {
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      background-color: #FFFFFF;
      border-radius: 8px;
      border: 1px solid #D9D9D9;
      height: 50px;
      padding: 12px 16px;
      background-position: right 16px top 50%;
      background-size: 16px;

      &::placeholder {
        color: #B3B3B3;
      }
    }

    .advanced-bar {
      background: var(--grey-custom);
      border-radius: 20px;
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 22px 46px;
    }

    .button-primary, .button-secondary {
      min-width: initial;
      border-radius: 10px;
      width: 139px;
      height: 48px;
      padding: 0 0;
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 24px;
      justify-content: center;
    }

    .button-secondary {
      position: relative;

      &.active-filters {
        border: 2px solid #730D83;

        span {
          position: absolute;
          top: -8px;
          right: -8px;
          background-color: #730D83;
          border-radius: 50%;
          width: 21px;
          height: 21px;
          color: #FFFFFF;
          font-size: 12px;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center
        }
      }
    }

    .button-search {
      display: flex;
      height: 48px;
      border: none;
      background: none;
      position: relative;
      align-items: center;
      justify-self: center;
      cursor: pointer;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: var(--grey-dark-custom);
      width: 210px;
      margin-left: 24px;
      padding: 0 20px;
      border-radius: 10px 10px 0 0;

      img {
        transform: translate(0,0) rotate(0deg);
        animation: all 200ms ease-in-out;
        margin-left: 8px;
      }

      &.is-active {
        background: var(--grey-custom);

        img {
          transform: translate(0, 0) rotate(180deg);
          animation: all 200ms ease-in-out;
          margin-right: 0;
          margin-left: 8px;
        }

        &:after {
          content: '';
          background: var(--grey-custom);
          position: absolute;
          height: 10px;
          width: 100%;
          bottom: -10px;
          left: 0;
        }
      }
    }

    .range-slider {
      width: 300px;
      height: 60px;
      position: relative;
      margin: 0px 40px 0px 40px;
    }

    .range-slider input[type=range] {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    label {
      font-size: 12px;
    }

    .title {
      font-weight: 800;
      position: absolute;
      top: 0;
      left: 0;
    }

    .minValue {
      text-align: right;
      position: absolute;
      left: 0;
      bottom: 6px;
    }

    .maxValue {
      text-align: left;
      position: absolute;
      right: 0;
      bottom: 6px;
    }

    input[type=range] {
      appearance: none;
      -webkit-appearance: none;
      width: 100%;
    }

    input[type=range]:focus {
      outline: none;
    }

    input[type=range]:focus::-webkit-slider-runnable-track {
      background: var(--green-custom);
    }

    input[type=range]:focus::-ms-fill-lower {
      background: var(--green-custom);
    }

    input[type=range]:focus::-ms-fill-upper {
      background: var(--green-custom);
    }

    input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 5px;
      cursor: pointer;
      animation: 0.2s;
      background: var(--green-custom);
      border-radius: 1px;
      box-shadow: none;
      border: 0;
    }

    input[type=range]::-webkit-slider-thumb {
      z-index: 2;
      position: relative;
      box-shadow: 0px 0px 0px #000;
      border: 1px solid var(--purple-custom);
      height: 18px;
      width: 18px;
      border-radius: 25px;
      background: var(--purple-custom);
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -7px;
    }
  }

  .properties-section {
    padding: 0px 0 40px;

    .container {
      display: flex;
      justify-content: space-between;
      max-width: 1200px;
      align-items: center;

      &.container-properties {
        max-width: 1200px;
        margin-top: 60px;
        flex: 1;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
    }

    .view-more {
      margin: 60px 0 0;
      text-align: center;

      a {
        outline: none;
        text-decoration: none;
        font-weight: var(--weight-bold);
        color: var(--green-custom);
        cursor: pointer;
      }
    }

    .box-properties {
      position: relative;
      min-height: 371px;
      display: flex;
      max-width: 280px;
      width: 100%;
      margin-bottom: 30px;
      margin-right: 26px;

      &:nth-child(4n) {
        margin-right: 0;
      }

      .properties-image {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 25px;
        height: 371px;
        width: 100%;
        object-fit: cover;
      }

      a {
        text-decoration: none;
        width: 100%;
      }

      .properties-content {
        position: relative;
        z-index: 5;
        display: flex;
        height: 371px;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        padding: 32px 24px;
        border-radius: 25px;
        background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */

        .tag {
          background: var(--orange-custom);
          color: var(--gainsboro);
          font-size: var(--size-small);
          display: flex;
          align-items: center;
          height: 33px;
          max-width: 130px;
          padding: 0 0;
          border-radius: 10px;
          justify-content: center;
          opacity: 0.0;
        }

        .logo {
          width: 66px;
          height: auto;
          margin-bottom: 12px;
          text-decoration: none;
        }

        a {
          color: var(--white);
          text-decoration: none;
          width: 100%;
        }

        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: var(--white);
          margin-bottom: 4px;
        }

        .logo {
          width: 66px;
          height: auto;
          margin-bottom: 12px;
        }

        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: var(--white);
          margin-bottom: 4px;
        }

        .locale {
          font-weight: 300;
          color: var(--white);
          line-height: 19px;
          font-size: var(--size-button);
          margin-bottom: 4px;
        }

        .infos {
          font-weight: 400;
          color: var(--white);
          line-height: 14px;
          font-size: var(--size-small);
          text-transform: uppercase;
          display: inline-block;
          margin-right: 16px;
          position: relative;
          font-weight: 300;
          text-transform: uppercase;

          &::after {
            content: '';
            position: absolute;
            right: -10px;
            top: 4px;
            background: var(--white);
            width: 5px;
            height: 5px;
            border-radius: 100px;
          }
          &:last-child {
            margin-right: 0px;

            &::after {
              display: none;
            }
          }
        }
      }
    }

  }

  @media (max-width: 1366px) {
    .properties-section .box-properties { margin-right: 15px;}
  }

  @media (max-width: 1024px) {
    .properties-filter {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
    }
  }

  @media (max-width: 768px) {
    margin-top: 0px;

    .advanced-bar {
      flex-direction: column;
    }
    .properties-header {
      margin-top: 60px;

      ul {
        li {
          font-size: var(--size-regular);
        }
      }
    }

    .properties-filter {
      margin: 28px 16px 0 16px;

      .shadow {
        display: none;
      }

      .container {
        .search {
          margin: 0 0 12px;
          max-width: 100%;
        }

        ::v-deep .dropdown-select {
          max-width: 100% !important;
        }

        .select {
          max-width: initial;
          width: 100%;
          margin: 0 0 12px;
          max-width: 100% !important;
        }

        input {
          max-width: 100% !important;
        }

        &--cell {
          flex-direction: column;
          align-items: center;
        }
      }
    }

    .properties-list {
      &--filter-container {
        padding: 0 15px;
      }
    }

    .properties-section {
      padding: 0 0;

      .container {
        &.container-properties {
          flex: 1;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          margin-top: 32px;
        }
      }
      .box-properties {
        min-height: inherit;
        margin-bottom: 20px;
        .properties-image {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 350px) {
    .container.container-properties {
      .box-properties {
        .properties-content {
          height: 345px;
        }
        .properties-image {
          height: 345px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .properties-list .properties-section .container .box-properties {
    margin-right: 0px;
    max-width: 100%;
  }

  .properties-list .properties-filter {
      margin: 0px;
  }
}
</style>
