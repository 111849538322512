<template>
  <div
    class="dropdown-select"
    :class="{
      'dropdown-select--active': thereAreSelectedValues,
      'dropdown-select--disabled': isDisabled }"
      ref="dropdownRef"
      @click.self="toggleDropdown"
  >
    <label v-if="thereAreSelectedValues && label" class="dropdown-select__label" for="input">
      {{ label  }}
    </label>
    <input
      v-if="multiple"
      :class="{ 'dropdown-select--active-input': thereAreSelectedValues && label }"
      type="textarea"
      id="input"
      :placeholder="label"
      :value="values"
      readonly
      @click.self="toggleDropdown"
    >
    <input
      v-else
      type="text"
      :class="{ 'dropdown-select--active-input': thereAreSelectedValues && label }"
      :placeholder="label"
      id="input"
      :value="selected?.name"
      readonly
      @click.self="toggleDropdown"
    >
    <LoadingIcon v-if="isLoading" class="loading-icon" style="width: 16px; height: 16px;" />
    <div class="close-icon" v-else-if="selected?.key && !multiple && !unremovable">
      <img src="@/assets/icon-close.svg" @click.self="handleRemoveValues">
    </div>
    <div class="counter" v-else-if="selectedValues.length > 0">
      +{{ selectedValues.length }}
    </div>
    <img class="dropdown-select__icon" :class="{ 'is-open': isOpen }" src="@/assets/icons/arrow-down.svg" @click.self="toggleDropdown">

    <transition name="dropdown-select">
      <div v-if="isOpen" class="dropdown-select__group" :class="{ up: dropdownDirection === 'up', modal: modal }">
        <div v-if="!multiple && items.length > 0" class="dropdown-select__group-items">
          <button
            v-for="(item) in items"
            :key="item.key"
            class="dropdown-select__group-item"
            :class="{ 'selected': item.key === selected?.key }"
            @click="handleItemSelect(item)"
          >
            {{ item.name }}
          </button>
        </div>
        <div v-else-if="multiple && items.length > 0" class="dropdown-select__group-items">
          <Checkbox
            v-for="item in items"
            :key="item.key"
            :label="item.name"
            :value="item"
            :selectedValues="selectedValues.map((value) => {
              return {
                key: value.key
              }
            })"
            @checked="handleChecked"
            />
        </div>
        <div v-else class="empty">
          <p>Nenhuma opção disponível</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Checkbox from '@/components/checkbox/Checkbox.vue'
import LoadingIcon from '@/components/loading/LoadingIcon.vue'
export default {
  name: 'DropdownSelect',
  components: { Checkbox, LoadingIcon },
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    selectedValues: {
      type: Array,
      default: () => []
    },
    selectedValue: {
      type: [String, Number],
      default: () => ''
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    unremovable: {
      type: Boolean,
      default: false
    },
    dropdownDirection: {
      type: String,
      default: 'down'
    },
    modal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpen: false,
      selected: {
        key: '',
        name: ''
      }
    }
  },
  computed: {
    values () {
      return this.selectedValues?.map((item) => item.name).join(',') || []
    },
    thereAreSelectedValues () {
      return Boolean(this.selected?.key || this.selectedValues?.length > 0)
    },
    isDisabled () {
      return this.isLoading || this.disabled
    }
  },
  watch: {
    selectedValue () {
      if (this.selectedValue) {
        this.selected = this.items.find((item) => {
          if (item.key) {
            return item.key === this.selectedValue
          }

          return this.selected
        })
      } else {
        this.selected = ''
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    toggleDropdown () {
      this.isOpen = !this.isOpen
    },

    handleItemSelect (item) {
      this.selected = item
      this.toggleDropdown()

      this.$emit('selected', item)
    },

    handleChecked (item) {
      this.$emit('selected', item)
    },

    handleRemoveValues () {
      this.selected = ''
      this.$emit('selected', '')
    },

    handleClickOutside (event) {
      const element = this.$refs.dropdownRef
      if (!element.contains(event.target)) {
        this.isOpen = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-select {
  display: flex;
  align-items: center;
  position: relative;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  padding: 12px 16px;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  cursor: pointer;
  transition: border 0.5s ease, background-color 0.5s ease;

  &:hover, &:focus, &:target {
    transition: border 0.5s ease, background-color 0.5s ease;
    border: 1px solid #730D83;
    background-color: rgba(246, 236, 247, 0.32);
  }

  &--active {
    transition: border 0.5s ease, background-color 0.5s ease;
    border: 1px solid #730D83;
    background-color: rgba(246, 236, 247, 0.32);
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--active-input {
    margin-top: 8px;
  }

  &__label {
    position: absolute;
    top: 6px;
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
    color: #666666;
  }

  input {
    border: none;
    background: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    max-width: 155px;
    cursor: pointer;

    &::placeholder {
      color: #B3B3B3;
    }
  }

  &__icon {
    position: absolute;
    right: 16px;
    transition: transform 0.5s ease;
  }

  &__group {
    position: absolute;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    top: 100%;
    left: 0;
    margin-top: 12px;
    box-shadow: 0px 2px 12px 0px rgba(121, 109, 127, 0.15);
    max-height: 330px;
    overflow-y: auto;
    z-index: 10;

    &.up {
      top: auto;
      bottom: 100%;
    }

    &-items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px
    }

    &-item {
      text-align: left;
      border: none;
      background-color: transparent;
      outline: none;
      font-size: 16px;
      padding: 8px 16px;
      border-radius: 8px;
      width: 100%;
      font-weight: 400;
      color: #666666;
      cursor: pointer;

      &:hover {
        background: #F6ECF7;
        color: #730D83;
      }
    }
  }
}

.close-icon {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  height: 26px;
  width: 26px;
  right: 36px;
  top: 10px;
  width: 60px;
  height: 18px;
  cursor: pointer;

  &:hover {
    img {
      transition: all 0.5s ease;
      background-color: #F6ECF7;
    }
  }

  img {
    padding: 6px;
    width: 26px;
    height: 26px;
    object-fit: contain;
    border-radius: 50%;
    transition: all 0.5s ease;
  }
}

.counter {
  position: absolute;
  right: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #F6ECF7;
  color: #730D83;
  font-size: 12px;
  font-weight: 700;
}

.loading-icon {
  position: absolute;
  right: 36px;
}

.is-open {
  transition: transform 0.5s ease;
  transform: rotate(180deg);
}

.empty {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}

.selected {
  background: #F6ECF7;
  color: #730D83;
}

.modal {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100% !important;
  max-height: 500px;
  max-width: 300px;
}

.dropdown-select-enter {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dropdown-select-enter-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}
</style>
