<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal-backoffice">
        <div class="modal-header">
          <slot name="header" />
          <img src="~@/assets/icon-close.svg" alt="" @click="close" />
        </div>
        <div class="modal-body">
          <slot name="body" />
        </div>
        <slot name="footer" />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>

main {
  .modal-fade-enter,
  .modal-fade-leave-active {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

    .modal-backoffice {
      background: var(--white);
      box-shadow: none;
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      max-width: 980px;
      border-radius: 10px;
      width: 100%;
      min-height: 166px;
      max-height: 90%;
      height: auto;
      text-align: left;
      position: relative;
      z-index: 1000;
      color: var(--black);

      .modal-header {
        text-align: center;
        padding: 0px 40px 40px;
        border-bottom: none;

        img {
          position: absolute;
          right: 0px;
          top: 0px;
          cursor: pointer;
        }
      }

      .modal-body {
        position: relative;
        padding: 0px 40px 0px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .modal-backdrop {
      .modal-backoffice {
        margin: 0px 15px;

        .modal-header {
          padding: 20px 15px 20px;
          img {
            right: 20px;
            top: 0px;
          }
        }

        .modal-body {
          padding: 30px 15px 40px;
        }
      }
    }
  }
}
</style>
