<template>
  <Modal @close="close" class="properties-modal">
    <template slot="header">
      <h2 class="properties-modal--title">Filtros</h2>
    </template>
    <template slot="body">
      <div class="properties-filter" :style="modalPaddingBottom">
        <div class="container">
          <LabeledFields label="Tipo de imóvel">
            <div class="container--multiple">
              <div class="container--cell">
                <OptionsSelect
                  :items="saleTypes"
                  :selected="selectedSaleType"
                  @selected="handleSaleTypeSelect"
                />
              </div>

              <div class="container--multiple-group">
                <div class="container--grid">
                  <Checkbox
                    v-for="status in statuses"
                    :key="status.key"
                    @checked="handleStatusesCheckbox"
                    :label="status.name"
                    :value="status"
                    :selectedValues="selectedPropertyStatuses.map((status) => {
                      return {
                        key: status.key
                      }
                    })" />
                </div>
                <div class="container--grid">
                  <Checkbox
                    v-for="payment in payments"
                    :key="payment.key"
                    @checked="handlePaymentsCheckbox"
                    :label="payment.name"
                    :value="payment"
                    :selectedValues="selectedPayments.map((payment) => {
                      return {
                        key: payment.key
                      }
                    })" />
                </div>
              </div>
            </div>
          </LabeledFields>

          <LabeledFields label="Suítes">
            <OptionsSelect :items="defaultMinMaxOptions.max" :initialize="suites.max ? true : false" :selected="suites.max" @selected="handleSuitesSelect" />
          </LabeledFields>

          <LabeledFields label="Garagem">
            <OptionsSelect :items="defaultMinMaxOptions.max" :initialize="garage.max ? true : false" :selected="garage.max" @selected="handleGarageSelect" />
          </LabeledFields>

          <LabeledFields label="Faixa de preço">
            <div class="container--cell">
              <Money v-model="selectedMinimumValue" v-bind="money" placeholder="Mínimo" />
              <Money v-model="selectedMaximumValue" v-bind="money" placeholder="Máximo" />
            </div>
          </LabeledFields>

          <LabeledFields label="Área útil">
            <div class="container--cell">
              <input type="number" v-model="selectedMinimumFootage" placeholder="m²" />
              <input type="number" v-model="selectedMaximumFootage" placeholder="m²" />
            </div>
          </LabeledFields>

          <LabeledFields label="Vantagens">
            <DropdownSelect
              @selected="handleFeaturesSelect"
              :selectedValues="selectedFeatures.map(feature => {
                return {
                  key: feature?.id,
                  name: feature?.attributes?.name
                }
              })"
              :items="features.map((feature) => { return { key: feature.id, name: feature.attributes.name } }).filter((feat) => (feat.name !== 'Todos'))"
              :disabled="isLoading"
              :isLoading="isLoading"
              multiple
              dropdownDirection="up"
              label="Vantagens"
            />
          </LabeledFields>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="properties-modal--footer">
        <button class="button-secondary" @click="resetFilters">Remover filtros</button>
        <button class="button-primary" @click="filterValues" :disabled="isLoading">Mostrar apartamentos</button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal'
import Checkbox from '@/components/checkbox/Checkbox.vue'
import OptionsSelect from '@/components/select/OptionsSelect.vue'
import LabeledFields from '@/components/labeled-fields/LabeledFields.vue'
import DropdownSelect from '@/components/select/DropdownSelect.vue'
import { Money } from 'v-money'
import { regionsService } from '@/services/regions.service'
import { defaultMinMaxOptions, statuses, payments } from './properties-options'

export default {
  name: 'PropertiesModal',
  components: { Modal, Money, Checkbox, OptionsSelect, LabeledFields, DropdownSelect },
  props: {
    propertyTypes: {
      type: Array,
      default: () => []
    },
    states: {
      type: Array,
      default: () => []
    },
    cities: {
      type: Array,
      default: () => []
    },
    features: {
      type: Array,
      default: () => []
    },
    saleTypes: {
      type: Array,
      default: () => []
    },
    preSetValues: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      minimumValue: '',
      maximumValue: '',
      selectedSaleType: '',
      selectedPropertyType: '',
      selectedState: '',
      selectedCity: '',
      selectedFeatures: [],
      selectedPropertyStatuses: [],
      selectedPayments: [],
      selectedMinBedrooms: '',
      selectedMaxBedrooms: '',
      garage: {
        min: '',
        max: ''
      },
      suites: {
        min: '',
        max: ''
      },
      selectedMinimumFootage: '',
      selectedMaximumFootage: '',
      selectedMinimumValue: '',
      selectedMaximumValue: '',
      isLoading: false,
      modalCities: [],
      searchText: '',
      defaultMinMaxOptions,
      footerHeight: 0,
      modalPaddingBottom: 0,
      statuses,
      payments
    }
  },

  computed: {
    citiesToList () {
      if (!this.modalCities.length) {
        return this.cities
      }

      return this.modalCities
    }
  },

  mounted () {
    this.selectedSaleType = this.preSetValues?.selectedSaleType
    this.selectedPropertyType = this.preSetValues?.selectedPropertyType
    this.selectedState = this.preSetValues?.selectedState
    this.selectedCity = this.cities?.find((city) => city.id === this.preSetValues?.selectedCity?.id) || ''
    this.selectedMinimumValue = this.preSetValues?.selectedMinimumValue
    this.selectedMaximumValue = this.preSetValues?.selectedMaximumValue
    this.selectedMaxBedrooms = this.preSetValues?.bedrooms.max
    this.selectedMinBedrooms = this.preSetValues?.bedrooms.min
    this.selectedMinimumFootage = this.preSetValues?.footage.min
    this.selectedMaximumFootage = this.preSetValues?.footage.max
    this.selectedPropertyStatuses = this.preSetValues?.statuses
    this.selectedFeatures = this.preSetValues?.features
    this.selectedPayments = this.preSetValues?.payments
    this.garage = this.preSetValues?.garage
    this.suites = this.preSetValues?.suites
    this.searchText = this.preSetValues?.search

    this.updateFooterHeight()
    this.updateModalPaddingBottom()
    window.addEventListener('resize', this.updateFooterHeight)
    window.addEventListener('resize', this.updateModalPaddingBottom)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.updateFooterHeight)
    window.removeEventListener('resize', this.updateModalPaddingBottom)
  },

  methods: {
    updateFooterHeight () {
      this.footerHeight = this.getFooterHeight()
    },

    updateModalPaddingBottom () {
      this.modalPaddingBottom = this.getModalPaddingBottom()
    },

    getFooterHeight () {
      const footer = document.querySelector('.properties-modal--footer')
      return footer ? footer.offsetHeight : 0
    },

    getModalPaddingBottom () {
      if (window.innerWidth <= 500) {
        return `padding-bottom: ${this.getFooterHeight() + 20}px`
      }
      return ''
    },

    filterValues () {
      const payload = {
        saleType: this.selectedSaleType,
        propertyType: this.selectedPropertyType,
        payments: this.selectedPayments,
        state: this.selectedState,
        city: this.selectedCity,
        features: this.selectedFeatures,
        bedrooms: {
          min: this.selectedMinBedrooms,
          max: this.selectedMaxBedrooms
        },
        garage: this.garage,
        minimumValue: this.selectedMinimumValue,
        maximumValue: this.selectedMaximumValue,
        footage: {
          min: this.selectedMinimumFootage,
          max: this.selectedMaximumFootage
        },
        suites: {
          min: this.suites.min,
          max: this.suites.max
        },
        statuses: this.selectedPropertyStatuses,
        search: this.searchText
      }

      this.$emit('filter', payload)
      this.close()
    },

    handleStatusesCheckbox (value) {
      if (this.selectedPropertyStatuses.find((status) => status.key === value.key)) {
        const index = this.selectedPropertyStatuses.findIndex(item => item.key === value.key)
        this.selectedPropertyStatuses.splice(index, 1)
        return
      }

      this.selectedPropertyStatuses = [...this.selectedPropertyStatuses, value]
    },

    handlePaymentsCheckbox (value) {
      if (this.selectedPayments.find((payment) => payment.key === value.key)) {
        const index = this.selectedPayments.findIndex(item => item.key === value.key)
        this.selectedPayments.splice(index, 1)
        return
      }

      this.selectedPayments = [...this.selectedPayments, value]
    },

    handleSaleTypeSelect (value) {
      this.selectedSaleType = value
    },

    handleSuitesSelect (value) {
      this.suites.max = value.key
    },

    handleGarageSelect (value) {
      this.garage.max = value.key
    },

    handleFeaturesSelect (value) {
      const feature = this.features.find((feature) => feature.id === value.key)
      if (this.selectedFeatures.find((feature) => feature.id === value.key)) {
        const index = this.selectedFeatures.findIndex(item => item.id === value.key)
        this.selectedFeatures.splice(index, 1)
        return
      }

      this.selectedFeatures = [...this.selectedFeatures, feature]
    },

    async getCities (stateId) {
      this.isLoading = true
      try {
        const firstValue = { id: '', attributes: { name: 'Todos' } }
        const params = { state_id: stateId }
        const { data: responseData } = await regionsService.retrieveRegions(params)
        this.modalCities = [firstValue, ...responseData.data]
      } catch {
        console.log('Ocorreu um erro inesperado.')
      } finally {
        this.isLoading = false
      }
    },

    refetchState () {
      const stateId = this.selectedState
      this.selectedCity = ''
      this.getCities(stateId)
    },

    resetFilters () {
      this.minimumValue = ''
      this.maximumValue = ''
      this.selectedSaleType = ''
      this.selectedPropertyType = ''
      this.selectedState = ''
      this.selectedCity = ''
      this.selectedFeatures = []
      this.selectedPropertyStatuses = []
      this.selectedPayments = []
      this.selectedMinBedrooms = ''
      this.selectedMaxBedrooms = ''
      this.garage = {
        min: '',
        max: ''
      }
      this.suites = {
        min: '',
        max: ''
      }
      this.selectedMinimumFootage = ''
      this.selectedMaximumFootage = ''
      this.selectedMinimumValue = ''
      this.selectedMaximumValue = ''
      this.close(true)
    },

    close (reset = false) {
      this.$emit('close', reset)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/select";

.properties-modal {
  &--footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 16px;
    border-top: 1px solid #EDEDED;
  }

  &--title {
    font-size: 20px;
    font-weight: 300;
    color: #2E2F32;
  }

  &.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1100 !important;
  }

  ::v-deep .modal-backoffice {
    padding: 0;
    gap: 16px;
    border-radius: 20px !important;
    max-width: 684px !important;
  }

  ::v-deep .modal-header {
    padding: 24px !important;
    border-bottom: 1px solid #EDEDED !important;
    img {
      left: 24px !important;
      top: 28px !important;
      height: 20px;
      width: 20px;
    }
  }

  ::v-deep .modal-body {
    padding: 0 24px !important;
  }
}

.properties-filter {
  display: flex;
  gap: 16px;

  .container {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 6px;
    gap: 24px;
    align-items: center;
    padding-bottom: 16px;

    &--multiple {
      display: flex;
      width: 100%;
      gap: 12px;
      flex-direction: column;

      &-group {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    &--cell {
      display: flex;
      gap: 12px;
      width: 100%;

      & .form-group {
        width: 100%;
        margin: 0;
      }

      &.buttons {
        justify-content: center;
      }
    }

    &--group {
      display: flex;
      flex-direction: column;
      width: 100%;

      p {
        font-size: var(--size-button);
        font-weight: var(--weight-light);
        color: var(--grey-dark-custom);
      }
    }

    &--grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-height: 240px;
      overflow-y: auto;
      gap: 8px;
      width: 100%;
    }
  }

  .select, input, .search-input {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    height: 50px;
    padding: 12px 16px;
    background-position: right 16px top 50%;
    background-size: 16px;

    &::placeholder {
      color: #B3B3B3;
    }
  }

  .form-group {
    position: relative;
    margin-bottom: 20px;
    margin-right: 20px;
  }
}

.button-primary,
.button-secondary {
  min-width: initial;
  border-radius: 8px;
  height: 48px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  justify-content: center;
}

.button-primary {
  border: 0px #FFFFFF solid;
}

.button-secondary {
  background-color: transparent;
  border: 0px #FFFFFF solid;
}

@media screen and (max-width: 768px) {
  .properties-filter {
    flex-direction: column;

    .container {
      &--multiple {
        gap: 24px;
      }
    }
  }
}

@media (max-width: 500px) {
  ::v-deep .modal-backoffice {
    overflow-y: hidden;
  }

  .properties-filter {
    max-height: 500px;
    padding-bottom: 20px;
    overflow-y: auto;

    .container {
      .container--grid {
        display: flex;
        flex-direction: column;
        max-height: initial;
        overflow-y: unset;
        gap: 12px;
      }
    }
  }

  .properties-modal {
    &--footer {
      position: absolute;
      bottom: 0;
      flex-direction: column;
      background: #fff;
      width: 100%;
    }
  }
}
</style>
