<template>
  <div class="labeled-fields">
    <h2>{{ label }}</h2>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'LabeledFields',
  props: {
    label: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.labeled-fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  h2 {
    font-size: 16px;
    font-weight: 400;
    color: #1E1E1E;
  }
}
</style>
