<template>
  <div class="options-select">
    <button v-for="item in items" :key="item.key" class="options-select__item" :class="{ selected: item.key === selectedItem.key }"  @click="selectItem(item)">
      {{ item.name || item.value }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'OptionsSelect',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selected: {
      type: [Object, Number, String],
      default: () => {}
    },
    initialize: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedItem: '',
      theresOnlyOneItem: false
    }
  },
  mounted () {
    if (this.items.length === 1) {
      this.theresOnlyOneItem = true
      this.selectedItem = this.items[0]
      this.emitSelectedItem(this.selectedItem)
      return
    }

    if (this.initialize) {
      this.selectedItem = this.items[0]
      this.emitSelectedItem(this.selectedItem)
    }
  },

  watch: {
    selected () {
      if (this.selected) {
        if (typeof this.selected === 'number') {
          this.selectedItem = this.items.find(item => item.key === this.selected)
          this.emitSelectedItem(this.selectedItem)
        }
      }
    }
  },

  methods: {
    selectItem (item) {
      if (this.theresOnlyOneItem) {
        return
      }

      if (item.key === this.selectedItem.key) {
        this.selectedItem = ''
      } else {
        this.selectedItem = item
      }

      this.emitSelectedItem(item)
    },

    emitSelectedItem (item) {
      this.$emit('selected', item)
    }
  }
}
</script>

<style lang="scss">
.options-select {
  display: flex;
  flex: 1;
  gap: 8px;

  &__item {
    max-width: 123px;
    min-width: 80px;
    height: 48px;
    width: 100%;
    border-radius: 8px;
    padding: 8px;
    background-color: #F5F5F5;
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    border: none;
    outline: none;
    transition: all 0.5s ease;
    cursor: pointer;

    &:hover {
      transition: all 0.5s ease;
      background-color: #730D83;
      color: #F2F2F3;
    }

    &.selected {
      transition: all 0.5s ease;
      background-color: #730D83;
      color: #F2F2F3;
    }
  }
}

@media (max-width: 768px) {
  .options-select {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 500px) {
  .options-select {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
