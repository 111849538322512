var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{staticClass:"properties-modal",on:{"close":_vm.close}},[_c('template',{slot:"header"},[_c('h2',{staticClass:"properties-modal--title"},[_vm._v("Filtros")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"properties-filter",style:(_vm.modalPaddingBottom)},[_c('div',{staticClass:"container"},[_c('LabeledFields',{attrs:{"label":"Tipo de imóvel"}},[_c('div',{staticClass:"container--multiple"},[_c('div',{staticClass:"container--cell"},[_c('OptionsSelect',{attrs:{"items":_vm.saleTypes,"selected":_vm.selectedSaleType},on:{"selected":_vm.handleSaleTypeSelect}})],1),_c('div',{staticClass:"container--multiple-group"},[_c('div',{staticClass:"container--grid"},_vm._l((_vm.statuses),function(status){return _c('Checkbox',{key:status.key,attrs:{"label":status.name,"value":status,"selectedValues":_vm.selectedPropertyStatuses.map((status) => {
                    return {
                      key: status.key
                    }
                  })},on:{"checked":_vm.handleStatusesCheckbox}})}),1),_c('div',{staticClass:"container--grid"},_vm._l((_vm.payments),function(payment){return _c('Checkbox',{key:payment.key,attrs:{"label":payment.name,"value":payment,"selectedValues":_vm.selectedPayments.map((payment) => {
                    return {
                      key: payment.key
                    }
                  })},on:{"checked":_vm.handlePaymentsCheckbox}})}),1)])])]),_c('LabeledFields',{attrs:{"label":"Suítes"}},[_c('OptionsSelect',{attrs:{"items":_vm.defaultMinMaxOptions.max,"initialize":_vm.suites.max ? true : false,"selected":_vm.suites.max},on:{"selected":_vm.handleSuitesSelect}})],1),_c('LabeledFields',{attrs:{"label":"Garagem"}},[_c('OptionsSelect',{attrs:{"items":_vm.defaultMinMaxOptions.max,"initialize":_vm.garage.max ? true : false,"selected":_vm.garage.max},on:{"selected":_vm.handleGarageSelect}})],1),_c('LabeledFields',{attrs:{"label":"Faixa de preço"}},[_c('div',{staticClass:"container--cell"},[_c('Money',_vm._b({attrs:{"placeholder":"Mínimo"},model:{value:(_vm.selectedMinimumValue),callback:function ($$v) {_vm.selectedMinimumValue=$$v},expression:"selectedMinimumValue"}},'Money',_vm.money,false)),_c('Money',_vm._b({attrs:{"placeholder":"Máximo"},model:{value:(_vm.selectedMaximumValue),callback:function ($$v) {_vm.selectedMaximumValue=$$v},expression:"selectedMaximumValue"}},'Money',_vm.money,false))],1)]),_c('LabeledFields',{attrs:{"label":"Área útil"}},[_c('div',{staticClass:"container--cell"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedMinimumFootage),expression:"selectedMinimumFootage"}],attrs:{"type":"number","placeholder":"m²"},domProps:{"value":(_vm.selectedMinimumFootage)},on:{"input":function($event){if($event.target.composing)return;_vm.selectedMinimumFootage=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedMaximumFootage),expression:"selectedMaximumFootage"}],attrs:{"type":"number","placeholder":"m²"},domProps:{"value":(_vm.selectedMaximumFootage)},on:{"input":function($event){if($event.target.composing)return;_vm.selectedMaximumFootage=$event.target.value}}})])]),_c('LabeledFields',{attrs:{"label":"Vantagens"}},[_c('DropdownSelect',{attrs:{"selectedValues":_vm.selectedFeatures.map(feature => {
              return {
                key: feature?.id,
                name: feature?.attributes?.name
              }
            }),"items":_vm.features.map((feature) => { return { key: feature.id, name: feature.attributes.name } }).filter((feat) => (feat.name !== 'Todos')),"disabled":_vm.isLoading,"isLoading":_vm.isLoading,"multiple":"","dropdownDirection":"up","label":"Vantagens"},on:{"selected":_vm.handleFeaturesSelect}})],1)],1)])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"properties-modal--footer"},[_c('button',{staticClass:"button-secondary",on:{"click":_vm.resetFilters}},[_vm._v("Remover filtros")]),_c('button',{staticClass:"button-primary",attrs:{"disabled":_vm.isLoading},on:{"click":_vm.filterValues}},[_vm._v("Mostrar apartamentos")])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }