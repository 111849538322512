export const replaceQueryParams = (queryString = {}) => {
  history.replaceState(
    history.state,
    '',
    Object.keys(queryString).length === 0 ? window.location.pathname : `?${new URLSearchParams(queryString)}`
  )
}

export const pushQueryParams = (queryString = {}) => {
  history.pushState(
    history.state,
    '',
    Object.keys(queryString).length === 0 ? window.location.pathname : `?${new URLSearchParams(queryString)}`
  )
}

export const clearQueryParams = () => {
  replaceQueryParams()
}
