export const defaultMinMaxOptions = {
  min: [
    {
      name: '1',
      key: 1
    },
    {
      name: '2',
      key: 2
    },
    {
      name: '3',
      key: 3
    },
    {
      name: '4',
      key: 4
    },
    {
      name: '5',
      key: 5
    }
  ],
  max: [
    {
      name: '1',
      key: 1
    },
    {
      name: '2',
      key: 2
    },
    {
      name: '3',
      key: 3
    },
    {
      name: '4',
      key: 4
    },
    {
      name: '5+',
      key: 999
    }
  ]
}

export const statuses = [
  {
    name: 'Imóvel pronto',
    key: 'pre_release'
  },
  {
    name: 'Em construção',
    key: 'under_construction'
  }
]

export const payments = [
  {
    name: 'Aceita financiamento',
    key: 'financing'
  },
  {
    name: 'Aceita FGTS',
    key: 'fgts'
  }
]

export const propertyTypes = [
  { key: 'house', name: 'Casa' },
  { key: 'apartment', name: 'Apartamento' },
  { key: 'flat', name: 'Flat/Apart-hotel' },
  { key: 'studio', name: 'Kitnet/Studio' },
  { key: 'room', name: 'Sala' },
  { key: 'shop', name: 'Loja' },
  { key: 'lot', name: 'Lote' },
  { key: 'rural', name: 'Rural' },
  { key: 'building', name: 'Prédio' },
  { key: 'shed', name: 'Galpão' }
]

export const saleTypes = [
  {
    key: 'sale', name: 'Venda'
  }
]
